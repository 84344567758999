import { useState } from 'react';

type Dictionary<T> = { [key: string]: T };
type Key = string | number;

/**
 * source: https://github.com/matmalkowski/use-radio-group
 * @param initialSelection
 * @returns
 */
export const useRadioGroup = (initialSelection: Key): [Key, Dictionary<boolean>, (newKey: Key) => void] => {
  const [checked, setItems] = useState<Dictionary<boolean>>({ [initialSelection]: true });

  const setChecked = (newKey: Key): void => {
    setItems((prevChecked) => {
      const nextState = Object.keys(prevChecked).reduce((acc: Dictionary<boolean>, k: string) => {
        acc[k] = false;
        return acc;
      }, {});
      nextState[newKey] = true;
      return nextState;
    });
  };

  const value = Object.entries(checked).find((c) => c[1])?.[0];

  return [value, checked, setChecked];
};
