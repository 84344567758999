import { ClickableOverlay } from '@lp-root/src/components/mol.hack-overlay/hack-overlay.component';
import { Body, H3, Separator } from '@web/atomic';
import { Video } from '@web/atomic/legacy/atm.video/video.component';
import { BooleanContainer } from '@web/atomic/legacy/obj.abstract-container';
import { Modal } from '@web/atomic/legacy/obj.modal';
import { RelativeWrapper } from '@web/atomic/obj.wrappers';
import * as React from 'react';

interface ITestimonialVideoProps {
  context: string; // where the video is applied (this string is used for analytics purpose)
  name: string;
  description: string;
  videoUrl: string;
}

const TestimonialVideo: React.FunctionComponent<ITestimonialVideoProps> = (props) => {
  const videoUrl = props.videoUrl;

  return (
    <BooleanContainer>
      {(bool) => (
        <>
          <RelativeWrapper>
            {bool.value || <ClickableOverlay id={`ga-${props.context}-bottom-video`} onClick={bool.setTrue} />}
            <Video url={videoUrl} />
            <H3 cell>{props.name}</H3>
            <Body>{props.description}</Body>
            <Separator />
          </RelativeWrapper>
          <Modal opened={bool.value} onClose={bool.setFalse} noPadding>
            <Video url={videoUrl} disableSrcDoc={true} />
          </Modal>
        </>
      )}
    </BooleanContainer>
  );
};

export default TestimonialVideo;
