import { GatsbyButton } from '@components/atm.button/button.component';
import { externalPaths } from '@lp-root/src/utils/path';
import { Body, Col, FaIcon, Grid, HDisplay, Row, Separator, GrayColor } from '@web/atomic';
import { getWhatsappClickToChat } from '@global/utils/url/create-whatsapp-url';
import { ForcedFade } from '@web/atomic/legacy/obj.animation/animation.component.style';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import * as React from 'react';
import {
  HealthCarerLandingPageContentStyled,
  HealthCarerLandingPageImageStyled,
  HealthCarerLandingPageSectionStyled,
  HealthCarerLandingPageTitleStyled,
} from './health-carer-landing-page-hero-section.styled';
import { PartnersLogoComponent } from '@components/mol.partners-logo/PartnersLogoComponent';

const ComponentToBeMemoized: React.FunctionComponent = (props) => {
  const data = useStaticQuery(graphql`
    {
      mobileImage: file(relativePath: { eq: "mobile-hero-img-hcp.png" }) {
        childImageSharp {
          gatsbyImageData(width: 768, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      desktopImage: file(relativePath: { eq: "hero-img-hcp.png" }) {
        childImageSharp {
          gatsbyImageData(width: 601, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      eretzbio: file(relativePath: { eq: "logo_eretz-einstein.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, layout: FIXED, placeholder: NONE)
        }
      }
    }
  `);

  const images = withArtDirection(getImage(data.desktopImage.childImageSharp.gatsbyImageData), [
    {
      media: '(max-width: 768px)',
      image: getImage(data.mobileImage.childImageSharp.gatsbyImageData),
    },
  ]);

  return (
    <HealthCarerLandingPageSectionStyled id={'inicio'}>
      <Grid>
        <Row middle={'xs'}>
          <Col xs={false} lg={7}>
            <GatsbyImage
              image={images}
              alt="Médico usando ferramenta"
              imgStyle={{ objectFit: 'contain', objectPosition: `bottom center` }}
            />
          </Col>
          <Col xs={12} mdOffset={7} md={5} lgOffset={0}>
            <HealthCarerLandingPageContentStyled>
              <HealthCarerLandingPageTitleStyled>
                <HDisplay>Trate a insônia dos seus pacientes com a Vigilantes do Sono!</HDisplay>
                <Body
                  role="tooltip"
                  data-microtip-position="top"
                  data-microtip-size="large"
                  aria-label={
                    'O programa também é efetivo na presença de comorbidades ajudando a reduzir quadros de ansiedade, depressão e dor dos pacientes. '
                  }
                >
                  Oferecemos uma ferramenta digital de Terapia Cognitivo Comportamental para Insônia (TCC-i) para você cuidar do sono dos
                  seus pacientes. <FaIcon.Question />
                </Body>
              </HealthCarerLandingPageTitleStyled>
              <Separator />
              <ForcedFade show={true} delay={'.5s'}>
                <Row>
                  <Col xs={12}>
                    <GatsbyButton id={'ga-hcp-lp-top-cta'} external to={externalPaths.portal.path} kind={'call-to-action'} expanded>
                      Começar agora mesmo
                    </GatsbyButton>
                    <Separator />
                  </Col>
                </Row>
                <Row center={'xs'}>
                  <Col xs={12}>
                    <GatsbyButton id={'ga-hcp-lp-top-primary'} to={getWhatsappClickToChat('sales')} external kind={'primary'} expanded>
                      Falar com um consultor
                    </GatsbyButton>
                    <Separator />
                  </Col>
                </Row>
              </ForcedFade>
            </HealthCarerLandingPageContentStyled>
            <PartnersLogoComponent kind={'default'} imgs={data.eretzbio.childImageSharp.gatsbyImageData} />
          </Col>
        </Row>
      </Grid>
      <HealthCarerLandingPageImageStyled>
        <GatsbyImage loading="eager" image={images} alt="Médico usando ferramenta" />
      </HealthCarerLandingPageImageStyled>
    </HealthCarerLandingPageSectionStyled>
  );
};

export const HealthCarerLandingPageHeroSection: React.FunctionComponent = React.memo(ComponentToBeMemoized, () => true);
