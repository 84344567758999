import { GatsbyButton } from '@lp-root/src/components/atm.button/button.component';
import LargeSeparatorRow from '@lp-root/src/components/atm.large-separator-row/large-separator-row.component';
import { Col, FeaturedH2, Grid, Row } from '@web/atomic';
import { getWhatsappClickToChat } from '@global/utils/url/create-whatsapp-url';
import * as React from 'react';

export const HcpLandingPageWantToKnowMoreSection: React.FunctionComponent = () => {
  return (
    <Grid>
      <Row mb mt center="xs">
        <Col xs={12} sm={6} md={4}>
          <FeaturedH2>Quer saber mais?</FeaturedH2>
          <GatsbyButton id="ga-hcp-landing-page-know-more" kind="primary" expanded external to={getWhatsappClickToChat('sales')}>
            Falar com um consultor
          </GatsbyButton>
        </Col>
      </Row>
      <LargeSeparatorRow />
      <LargeSeparatorRow />
    </Grid>
  );
};
