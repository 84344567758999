import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import { PageProps } from '@lp-src/utils/local-types';
import * as React from 'react';
import { SEO } from '../components/legacy/mol.seo/seo.component';
import IndexLayout from '../components/org.layout/layout.component';
import HealthCarerLandingPage from '../modules/health-carer-landing-page/health-carer-landing-page';

const ProfissionaisDaSaude: React.FunctionComponent<PageProps> = (props) => {
  return (
    <IndexLayout location={props.location} cta={null} whatsappKind={'sales'}>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Trate a insônia dos seus pacientes com a Vigilantes do Sono!',
          description:
            'Oferecemos uma ferramenta digital de Terapia Cognitivo Comportamental para Insônia (TCC-i) para você cuidar do sono dos seus pacientes. O programa também é efetivo na presença de comorbidades ajudando a reduzir quadros de ansiedade, depressão e dor dos pacientes.',
        }}
      />
      <HealthCarerLandingPage />
    </IndexLayout>
  );
};

export default ProfissionaisDaSaude;

export const query = graphql`
  query ProfissionaisDaSaudePage {
    site {
      ...SiteUrl
    }
  }
`;
