import { LoadableHcpLandingPageMediaSection } from '@lp-root/src/components/org.media-section/media-section.loadable';
import { HeaderHeight } from '@web/atomic';
import { useMobile } from '@web/atomic/obj.custom-hooks/mobile.hook';
import * as React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { HcpLandingPageFAQSection } from './components/hcp-landing-page-faq-section.component';
import LoadableHcpLandingPagePartnershipSection from './components/hcp-landing-page-partnership-section.loadable';
import { HcpLandingPageResultsSection } from './components/hcp-landing-page-results-section.component';
import { HcpLandingPageScientificValidationSection } from './components/hcp-landing-page-scientific-validation-section.component';
import { HcpLandingPageTestiomonialsSection } from './components/hcp-landing-page-testiomonials-section.component';
import { HcpLandingPageWantToKnowMoreSection } from './components/hcp-landing-page-want-to-know-more-section.component';
import { HcpLandingPageWhatIsVigilantesDoSonoSection } from './components/hcp-landing-page-what-is-vigilantes-do-sono-section.component';
import { HealthCarerLandingPageHeroSection } from './components/health-carer-landing-page-hero-section.component';

const HealthCarerLandingPage: React.FunctionComponent = () => {
  const mobile = useMobile();
  return (
    // react-scrollmagic POG: this "div" mustn't be removed https://stackoverflow.com/a/56116125/3670829
    <div>
      <Controller>
        <section>
          <HealthCarerLandingPageHeroSection />
        </section>
        <Scene duration={3000} pin triggerHook={0} offset={-(mobile ? HeaderHeight.Mobile - 24 : HeaderHeight.Desk)}>
          {(progress) => (
            <section>
              <HcpLandingPageResultsSection progress={progress} />
            </section>
          )}
        </Scene>
        <Scene duration={200}>
          {(progress) => (
            <section>
              <HcpLandingPageWhatIsVigilantesDoSonoSection progress={progress} />
            </section>
          )}
        </Scene>
        <Scene duration={600} offset={100}>
          {(progress) => (
            <section>
              <LoadableHcpLandingPagePartnershipSection progress={progress} />
            </section>
          )}
        </Scene>
        {/* <HcpLandingPageHowItWorksSection /> */}
        <section>
          <HcpLandingPageScientificValidationSection />
        </section>
        <section>
          <HcpLandingPageTestiomonialsSection />
        </section>
        <section>
          <HcpLandingPageFAQSection />
        </section>
        <section>
          <LoadableHcpLandingPageMediaSection />
        </section>
        <section>
          <HcpLandingPageWantToKnowMoreSection />
        </section>
      </Controller>
    </div>
  );
};

export default HealthCarerLandingPage;
