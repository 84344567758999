import { Border, BrandColor, GrayColor, HDisplay, LightColor, Spacing, BodyStyle } from '@web/atomic';
import styled, { css, keyframes } from 'styled-components';

export const HcpLandingPageResultsSectionStyled = styled.section`
  background-color: ${LightColor.Primary};
  height: 100vh;
`;

export interface HcpLandingPageResultsSectionAnimationState {
  /** ex: "No início" */
  arrowText: string;
  /** between 0 and 1 */
  arrowValue: number;
  /** between 0 and 1 */
  outlinedWidth: number;
  /** between 0 and 1 */
  filledWidth: number;

  /** ex: 72 "minutos" */
  tibValue: number;
  /** ex: 72 "minutos" */
  tstValue: number;
}

export enum HcpLandingPageResultsSectionAnimationStep {
  start = 0,
  twoWeek = 0.05,
  sevenWeek = 0.55,
}

export const mapToAnimationState = (step: HcpLandingPageResultsSectionAnimationStep): HcpLandingPageResultsSectionAnimationState => {
  const startTimeInBed = 8 * 60;
  const startTotalSleepTime = 5.5 * 60;
  const outlinedWidth = (tibDecrease: number) => (startTimeInBed - tibDecrease) / startTimeInBed;
  const filledWidth = (tstIncrease: number) => (startTotalSleepTime + tstIncrease) / startTimeInBed;

  switch (step) {
    case HcpLandingPageResultsSectionAnimationStep.start:
      return {
        arrowText: 'No início',
        arrowValue: 0,
        outlinedWidth: 1,
        filledWidth: startTotalSleepTime / startTimeInBed,
        tibValue: null,
        tstValue: null,
      };
    case HcpLandingPageResultsSectionAnimationStep.twoWeek: {
      const tibDecrease = 27;
      const tstIncrease = 17;
      return {
        arrowText: '2 semanas',
        arrowValue: 0.15,
        outlinedWidth: outlinedWidth(tibDecrease),
        filledWidth: filledWidth(tstIncrease),
        tibValue: tibDecrease,
        tstValue: tstIncrease,
      };
    }
    case HcpLandingPageResultsSectionAnimationStep.sevenWeek:
    default: {
      const tibDecrease = 74;
      const tstIncrease = 59;
      return {
        arrowText: '7 semanas',
        arrowValue: 0.5,
        outlinedWidth: outlinedWidth(tibDecrease),
        filledWidth: filledWidth(tstIncrease),
        tibValue: tibDecrease,
        tstValue: tstIncrease,
      };
    }
  }
};

const HighlightAnimation = css`
  display: inline-block;
  background: linear-gradient(to right, ${BrandColor.BackgroundGray} 50%, ${BrandColor.PortGore} 50%);
  background-size: 201% 100%;
  background-position: ${(props: { progressInCurrentStep: number }) => (1 - props.progressInCurrentStep) * 100}% bottom;
  border-radius: ${Border.Radius};
  padding: 4px;
  transition: all 0.1s ease-out;
`;

export const HcpLandingPageResultsSectionArrowLine = styled.div`
  position: relative;
  width: calc(100% - 4px);
  height: 1px;
  border: 0;
  border-top: 4px dashed ${GrayColor.White};
  margin-top: ${Spacing.Large};
  margin-bottom: ${Spacing.Large};

  @media all and (min-width: 64em) {
    margin-top: ${Spacing.XLarge};
  }

  @media all and (min-width: 81em) {
    margin-top: ${Spacing.XXXLarge};
    margin-bottom: ${Spacing.XXLarge};
  }

  &:after {
    content: '';
    position: absolute;
    right: -4px;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 8px 16px;
    border-color: transparent transparent transparent ${GrayColor.White};
  }
`;

interface HcpLandingPageResultsSectionArrowLegendProps {
  previousValue: number;
  previousContent: string;
  value: number;
  content: string;
}

const animateText = (props: HcpLandingPageResultsSectionArrowLegendProps) => keyframes`
  0% {
		content: '${props.previousContent}';
  }
  50% {
		color: ${LightColor.Primary};
	}
	100% {
		content: '${props.content}';
  }
`;

export const HcpLandingPageResultsSectionDescriptionStyled = styled.p`
  ${BodyStyle}
  white-space: nowrap;
`;

export const HcpLandingPageResultsSectionAnimationDuration = 0.7;
export const HcpLandingPageResultsSectionArrowLegend = styled.p<HcpLandingPageResultsSectionArrowLegendProps>`
	position: absolute;
	top: -16px;
	left: ${({ value }) => value * 100}%;
	transition: left ${HcpLandingPageResultsSectionAnimationDuration}s;
  padding: 0 ${Spacing.Medium};
  background: ${LightColor.Primary};
	&:after {
		content: '${(props) => props.content}';
		color: ${GrayColor.White};
		animation: ${(props) => animateText(props)} ${HcpLandingPageResultsSectionAnimationDuration}s forwards;
	}
`;

////////////////////////////////////////////////////////////////////////////////////////////////////

interface BarProps {
  width: number;
}

const height = 80;
const mobileHeight = 40;
const borderWidth = 2;
const barColor = BrandColor.MacaroniAndCheese;
const BarCommonStyle = css`
  height: ${mobileHeight}px;
  border-radius: ${mobileHeight / 2}px;
  border-width: ${borderWidth}px;
  border-color: ${barColor};
  @media all and (min-width: 48em) {
    height: ${height}px;
    border-radius: ${height / 2}px;
  }
`;

export const HcpLandingPageResultsSectionBarGroupWrapper = styled.div`
  position: relative;
  height: ${mobileHeight}px;
  @media all and (min-width: 48em) {
    height: ${height}px;
  }
`;
export const HcpLandingPageResultsSectionBarWrapper = styled.div<BarProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: ${(props) => props.width * 100}%;
  transition: width ${HcpLandingPageResultsSectionAnimationDuration}s;
`;
export const HcpLandingPageResultsSectionOutlinedBar = styled.div`
  border-style: solid;
  ${BarCommonStyle}
`;

export const HcpLandingPageResultsSectionDashedBar = styled.div`
  position: relative;
  border-style: dashed;
  width: 100%;
  ${BarCommonStyle}
`;

export const HcpLandingPageResultsSectionFillledBar = styled.div`
  border-style: solid;
  background-color: ${barColor};
  ${BarCommonStyle}
`;

////////////////////////////////////////////////////////////////////////////////////////////////////

const LegendCommonStyle = css`
  height: ${mobileHeight}px;
  width: ${mobileHeight}px;
  border-radius: ${mobileHeight / 2}px;
  border-style: solid;
  border-width: ${borderWidth}px;
  border-color: ${barColor};
  @media all and (min-width: 48em) {
    height: ${height}px;
    width: ${height}px;
    border-radius: ${height / 2}px;
  }
`;

export const HcpLandingPageResultsSectionOutlinedLegend = styled.div`
  ${LegendCommonStyle}
`;

export const HcpLandingPageResultsSectionFilledLegend = styled.div`
	${LegendCommonStyle}
	background-color: ${barColor};
`;

const mobileMargin = 4;
const margin = 16;
const size = 8;
const MinusStyle = css`
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    display: block;
    background-color: #fff;
    height: ${size}px;
    border-radius: ${size / 2}px;
    margin-top: -${size / 2}px;
    top: 50%;
    left: ${mobileMargin}px;
    right: ${mobileMargin}px;
  }
  height: ${mobileHeight - 2 * borderWidth}px;
  width: ${mobileHeight - 2 * borderWidth}px;
  @media all and (min-width: 48em) {
    height: ${height - 2 * borderWidth}px;
    width: ${height - 2 * borderWidth}px;
    &:after {
      left: ${margin}px;
      right: ${margin}px;
    }
  }
`;

export const HcpLandingPageResultsSectionDisplay = styled(HDisplay)`
  ${HighlightAnimation}
  color: ${LightColor.Primary};
`;

export const HcpLandingPageResultsSectionMinus = styled.div`
  ${MinusStyle}
`;

export const HcpLandingPageResultsSectionPlus = styled.div`
  ${MinusStyle}
  &:before {
    content: ' ';
    position: absolute;
    display: block;
    background-color: #fff;
    width: ${size}px;
    border-radius: ${size / 2}px;
    margin-left: -${size / 2}px;
    left: 50%;
    top: ${mobileMargin}px;
    bottom: ${mobileMargin}px;
  }
  @media all and (min-width: 48em) {
    &:before {
      top: ${margin}px;
      bottom: ${margin}px;
    }
  }
`;
