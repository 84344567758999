import { GrayColor, Spacing } from '@web/atomic';
import styled from 'styled-components';

export const HealthCarerLandingPageSectionStyled = styled.section`
  background-color: ${GrayColor.GrayXXLight};
  position: relative;
  overflow: hidden;
  padding: 0;

  @media all and (min-width: 46em) {
    display: flex;
    align-items: center;
    padding-bottom: ${Spacing.Large};
  }
  @media all and (min-width: 64em) {
    min-height: 500px;
  }
  @media all and (min-width: 75em) {
    min-height: 600px;
  }
`;

export const HealthCarerLandingPageContentStyled = styled.div`
  max-width: 480px;
  margin: auto;
`;

export const HealthCarerLandingPageTitleStyled = styled.div`
  padding: ${Spacing.XLarge} 0 0 0;
  text-align: center;

  @media all and (min-width: 425px) {
    padding: ${Spacing.XLarge} 0;
  }

  @media all and (min-width: 48em) {
    padding: ${Spacing.XLarge} 0 0 0;
    text-align: left;
  }
`;

export const HealthCarerLandingPageImageStyled = styled.div`
  position: inherit;
  max-height: 400px;

  @media all and (min-width: 64em) and (max-width: 76em) {
    position: absolute;
    left: -70px;
    bottom: -70px;
    width: 60%;
    max-width: 768px;
    max-height: 768px;
  }
  @media all and (max-width: 64em) {
    > .gatsby-image-wrapper > div:first-child {
      padding-top: 60% !important;
    }
  }
`;
