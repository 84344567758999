import LargeSeparatorRow from '@lp-root/src/components/atm.large-separator-row/large-separator-row.component';
import { usePrevious } from '@lp-root/src/components/obj.custom-hooks/previous.hook';
import { Body, FeaturedH2, Col, Grid, Hbox, HeaderHeight, LargeSeparator, Row, Separator } from '@web/atomic';
import { CollapsibleWrapperStyled } from '@web/atomic/atm.wrapper/wrapper.component';
import { Fade } from '@web/atomic/legacy/obj.animation/animation.component.style';
import { MatchMedia } from '@web/atomic/legacy/obj.match-media';
import { useClientRect } from '@web/atomic/obj.custom-hooks/client-rect.hook';
import { useMobile } from '@web/atomic/obj.custom-hooks/mobile.hook';
import * as React from 'react';

import { useEffect, useRef, useState } from 'react';
import {
  HcpLandingPageResultsSectionAnimationState,
  HcpLandingPageResultsSectionAnimationStep,
  HcpLandingPageResultsSectionArrowLegend,
  HcpLandingPageResultsSectionArrowLine,
  HcpLandingPageResultsSectionBarGroupWrapper,
  HcpLandingPageResultsSectionBarWrapper,
  HcpLandingPageResultsSectionDashedBar,
  HcpLandingPageResultsSectionDisplay,
  HcpLandingPageResultsSectionFilledLegend,
  HcpLandingPageResultsSectionFillledBar,
  HcpLandingPageResultsSectionMinus,
  HcpLandingPageResultsSectionOutlinedBar,
  HcpLandingPageResultsSectionOutlinedLegend,
  HcpLandingPageResultsSectionPlus,
  HcpLandingPageResultsSectionStyled,
  mapToAnimationState,
  HcpLandingPageResultsSectionDescriptionStyled,
} from './hcp-landing-page-results-section.component.styled';

interface IHcpLandingPageResultsSectionProps {
  progress: number;
}

export const HcpLandingPageResultsSection: React.FunctionComponent<IHcpLandingPageResultsSectionProps> = (props) => {
  const [step, setStep] = useState(HcpLandingPageResultsSectionAnimationStep.start);
  const previousStep = usePrevious(step);

  const progress = props.progress;
  useEffect(() => {
    const getNextStep = (progress: number) => {
      if (progress > HcpLandingPageResultsSectionAnimationStep.sevenWeek) return HcpLandingPageResultsSectionAnimationStep.sevenWeek;
      if (progress > HcpLandingPageResultsSectionAnimationStep.twoWeek) return HcpLandingPageResultsSectionAnimationStep.twoWeek;
      return HcpLandingPageResultsSectionAnimationStep.start;
    };
    const nextStep = getNextStep(progress);
    if (nextStep !== step) {
      setStep(nextStep);
    }
  }, [step, setStep, progress]);

  const progressInCurrentStep = getProgressInCurrentStep(progress, step);
  const previousData = mapToAnimationState(previousStep);
  const data = mapToAnimationState(step);

  const mobile = useMobile();

  const [wrapperRect, wrapperRef] = useClientRect();
  const maxWrapperRect = useRef<number>(0);
  if (wrapperRect?.height > maxWrapperRect.current) {
    maxWrapperRect.current = wrapperRect.height;
  }
  const [textRect, textRef] = useClientRect();
  const showDescription = !mobile || !(mobile && progress > 0 && maxWrapperRect.current > window.innerHeight - HeaderHeight.Mobile);

  return (
    <HcpLandingPageResultsSectionStyled>
      <div ref={wrapperRef}>
        <Grid>
          <Row mb>
            <Col
              xs={12}
              role="tooltip"
              data-microtip-position="bottom"
              data-microtip-size="large"
              aria-label={
                mobile
                  ? 'Com base em mais de 40 mil diários de sono analisados, os pacientes que começaram o programa conseguiram diminuir o tempo acordado na cama e aumentar o tempo de sono:'
                  : null
              }
            >
              <FeaturedH2 kind="light">Resultados</FeaturedH2>
            </Col>
          </Row>

          <CollapsibleWrapperStyled expanded={showDescription} contentHeight={textRect?.height} minHeight={0}>
            <div ref={textRef}>
              <Row center={'xs'} mb>
                <Col xs={12} md={8} lg={6}>
                  <Body kind="light">
                    Com base em mais de 40 mil diários de sono analisados, os pacientes que começaram o programa conseguiram diminuir o
                    tempo acordado na cama e aumentar o tempo de sono:
                  </Body>
                </Col>
              </Row>
            </div>
          </CollapsibleWrapperStyled>
          <Row mb>
            <Col xs={12}>
              <Timeline data={data} previousData={previousData} progressInCurrentStep={progressInCurrentStep} />
            </Col>
          </Row>
          <Row middle={'xs'} center="xs" mb>
            <Col xs={12} md={5} lg={4}>
              <Separator />
              <SleepBar data={data} />
              <Separator />
            </Col>
            <Col xs={12} md={5} mdOffset={1} lg={4} lgOffset={1}>
              <Separator />
              <SleepBarLegend data={data} previousData={previousData} progressInCurrentStep={progressInCurrentStep} />
              <Separator />
            </Col>
          </Row>

          <LargeSeparatorRow />
        </Grid>
      </div>
    </HcpLandingPageResultsSectionStyled>
  );
};

const getProgressInCurrentStep = (progress: number, step: HcpLandingPageResultsSectionAnimationStep) => {
  switch (step) {
    case HcpLandingPageResultsSectionAnimationStep.start:
      return (
        (progress - HcpLandingPageResultsSectionAnimationStep.start) /
        (HcpLandingPageResultsSectionAnimationStep.twoWeek - HcpLandingPageResultsSectionAnimationStep.start) /
        3
      );
    case HcpLandingPageResultsSectionAnimationStep.twoWeek:
      return (
        (progress - HcpLandingPageResultsSectionAnimationStep.twoWeek) /
        (HcpLandingPageResultsSectionAnimationStep.sevenWeek - HcpLandingPageResultsSectionAnimationStep.twoWeek)
      );
    case HcpLandingPageResultsSectionAnimationStep.sevenWeek:
      return (progress - HcpLandingPageResultsSectionAnimationStep.sevenWeek) / (1 - HcpLandingPageResultsSectionAnimationStep.sevenWeek);
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface ITimelineProps {
  data: HcpLandingPageResultsSectionAnimationState;
  previousData: HcpLandingPageResultsSectionAnimationState;
  progressInCurrentStep: number;
}

const Timeline: React.FunctionComponent<ITimelineProps> = (props) => {
  return (
    <HcpLandingPageResultsSectionArrowLine>
      <HcpLandingPageResultsSectionArrowLegend
        value={props.data.arrowValue}
        content={props.data.arrowText}
        previousValue={props.data.arrowValue}
        previousContent={props.previousData.arrowText || props.data.arrowText}
      />
    </HcpLandingPageResultsSectionArrowLine>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface ISleepBarProps {
  data: HcpLandingPageResultsSectionAnimationState;
}

const SleepBar: React.FunctionComponent<ISleepBarProps> = (props) => {
  return (
    <HcpLandingPageResultsSectionBarGroupWrapper>
      <HcpLandingPageResultsSectionBarWrapper width={1}>
        <HcpLandingPageResultsSectionDashedBar />
      </HcpLandingPageResultsSectionBarWrapper>

      <HcpLandingPageResultsSectionBarWrapper width={props.data.outlinedWidth}>
        <MatchMedia defaultMinWidth={`700px`}>
          {(md) => (
            <HcpLandingPageResultsSectionOutlinedBar
              role="tooltip"
              data-microtip-position={md ? 'bottom-right' : 'right'}
              data-microtip-size="small"
              aria-label={'Tempo acordado na cama'}
            />
          )}
        </MatchMedia>
      </HcpLandingPageResultsSectionBarWrapper>
      <HcpLandingPageResultsSectionBarWrapper width={props.data.filledWidth}>
        <HcpLandingPageResultsSectionFillledBar
          role="tooltip"
          data-microtip-position="top-left"
          data-microtip-size="small"
          aria-label={'Tempo dormindo'}
        />
      </HcpLandingPageResultsSectionBarWrapper>
    </HcpLandingPageResultsSectionBarGroupWrapper>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface ISleepBarLegendProps {
  data: HcpLandingPageResultsSectionAnimationState;
  previousData: HcpLandingPageResultsSectionAnimationState;
  progressInCurrentStep: number;
}

const SleepBarLegend: React.FunctionComponent<ISleepBarLegendProps> = (props) => {
  const { data, progressInCurrentStep } = props;

  const tibValue = data.tibValue;
  const tstValue = data.tstValue;

  const tibProgress = Math.min(1, Math.max(0, progressInCurrentStep - 0.05) * 5);
  const tstProgress = Math.min(1, Math.max(0, progressInCurrentStep - 0.5) * 10);
  return (
    <>
      <Hbox vAlign="center">
        <Hbox.Item wrap>
          <HcpLandingPageResultsSectionOutlinedLegend>
            <Fade show={!!data.tibValue}>
              <HcpLandingPageResultsSectionMinus />
            </Fade>
          </HcpLandingPageResultsSectionOutlinedLegend>
        </Hbox.Item>
        <Hbox.Separator />
        <Hbox.Separator />
        <Hbox.Separator />
        <Hbox.Item wrap>
          {!!data.tibValue && (
            <Fade show={!!data.tibValue}>
              <HcpLandingPageResultsSectionDisplay progressInCurrentStep={tibProgress} kind="light">
                {tibValue} minutos
              </HcpLandingPageResultsSectionDisplay>
            </Fade>
          )}
          <HcpLandingPageResultsSectionDescriptionStyled kind="light">
            Tempo acordado na cama {data.tibValue ? 'reduzido' : ''}
          </HcpLandingPageResultsSectionDescriptionStyled>
        </Hbox.Item>
      </Hbox>
      <LargeSeparator />
      <Hbox vAlign="center">
        <Hbox.Item wrap>
          <HcpLandingPageResultsSectionFilledLegend>
            {!!data.tstValue && <HcpLandingPageResultsSectionPlus />}
          </HcpLandingPageResultsSectionFilledLegend>
        </Hbox.Item>
        <Hbox.Separator />
        <Hbox.Separator />
        <Hbox.Separator />
        <Hbox.Item wrap>
          {!!data.tstValue && (
            <Fade show={!!data.tstValue}>
              <HcpLandingPageResultsSectionDisplay kind="light" progressInCurrentStep={tstProgress}>
                {tstValue} minutos
              </HcpLandingPageResultsSectionDisplay>
            </Fade>
          )}
          <HcpLandingPageResultsSectionDescriptionStyled kind="light">
            Tempo dormido {data.tstValue ? 'aumentado' : ''}
          </HcpLandingPageResultsSectionDescriptionStyled>
        </Hbox.Item>
      </Hbox>
    </>
  );
};
