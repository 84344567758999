import { ThumbShimmerBoxStyled } from '@web/atomic/legacy/mol.shimmer/shimmer.component.style';
import { LoadableLoadingWrapper } from '@lp-src/utils/loadable/loadable-loading-wrapper.component';
import * as React from 'react';
import loadable from '@loadable/component'

const LoadableHcpLandingPagePartnershipSection = loadable(
  () => import('./hcp-landing-page-partnership-section.component'),
  {
    fallback: (
      <ThumbShimmerBoxStyled height={'48px'} />
    )
  });

export default (props) => (
  <LoadableLoadingWrapper>
    <LoadableHcpLandingPagePartnershipSection {...props} />
  </LoadableLoadingWrapper>
);

