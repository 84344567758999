import { Blob1SectionStyled } from '@lp-root/src/components/atm.background/background.styled';
import LargeSeparatorRow from '@lp-root/src/components/atm.large-separator-row/large-separator-row.component';
import { Body, Col, FeaturedH2, Grid, H3, Row, Separator } from '@web/atomic';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

export const HcpLandingPageScientificValidationSection: React.FunctionComponent = () => {
  return (
    <>
      <Blob1SectionStyled>
        <a href="https://academic.oup.com/sleep/article-abstract/44/Supplement_2/A149/6260187" target="_blank" rel="noreferrer">
          <Grid>
            <Row>
              <Col xs={12}>
                <FeaturedH2>Validação científica da Vigilantes do Sono</FeaturedH2>
              </Col>
            </Row>
            <Row middle="xs">
              <Col xs={12} sm={12} md={5} lg={5}>
                <StaticImage
                  src={'img-study.png'}
                  alt={'376 dCBT-I with Chatbot and Artificial Intelligence: a feasibility study in Brazil'}
                />
                <Separator />
                <Separator />
              </Col>
              <Col xs={12} sm={12} md={7} lgOffset={1} lg={6}>
                <H3>Resultados</H3>
                <Body>
                  O estudo avaliou 42.802 diários do sono de 3.887 indivíduos que participaram no programa digital de Terapia
                  Cognitivo-Comportamental para insônia da Vigilantes do Sono.
                  <br /> Os resultados mostraram que a duração do sono aumentou 16,8 (11,9-21,6) minutos da primeira para a segunda semana e
                  67,3 (52,8-81,8) após a semana sete; paralelo a um aumento relativo de 34% na eficiência do sono entre as mulheres e 26%
                  entre os homens. Dos 296 participantes que tiveram uma resposta terapêutica relevante, com redução de 8 ou mais pontos no
                  Índice de Gravidade de Insônia (IGI), 66% completaram todas as sessões e 34% fizeram pelo menos metade do programa. A
                  remissão da insônia, detectada por um IGI final com menos de 7 pontos e indicando ausência de sintomas, foi observada em
                  55% dos indivíduos que começaram o programa tendo uma insônia leve (n=171) e em 33% daqueles que tinham insônia moderada a
                  grave (n=419). A mediana (interquartil) de engajamento no programa foi de 86% (65-98), calculada pela razão entre o número
                  de dias no programa e o número de diários de sono preenchidos, e 90% dos participantes que completaram todas as sessões
                  recomendam o programa.
                </Body>
              </Col>
            </Row>
          </Grid>
        </a>
      </Blob1SectionStyled>
      <Grid>
        <LargeSeparatorRow />
        <LargeSeparatorRow />
        <LargeSeparatorRow />
        <LargeSeparatorRow />
        <LargeSeparatorRow />
        <LargeSeparatorRow />
      </Grid>
    </>
  );
};
