import { hasDocument } from '@web/utils/platform';
import * as React from 'react';
import { HackOverlayStyled } from './hack-overlay.component.style';

interface HackOverlayProps {
  id?: string;
  onClick: () => void;
}

/**
 * This overlay is a transparent layer that can cover any element to "steal" its click events
 * It is shown only while document.hasFocus() === false
 *
 * Why?
 * This is useful in this project because the Messenger Webview starts itself
 * with document.hasFocus() === false until you tap on the screen for the first
 * time (if you scroll, the focus continues to be false; if you click on some
 * components, for example react-select, it conitnues to be false as well).
 * When document.hasFocus() === false, some events are not triggered, for example,
 * onFocus. Therefore, some components don't work until you have the window focus.
 * This component intercepts these clicks to make sure that document.hasFocus()
 * turns to true.
 *
 * @param props
 */
export const HackOverlay: React.FunctionComponent<HackOverlayProps> = (props) => {
  const [documentFocus, setDocumentFocus] = React.useState(false);
  const updateFocus = () => setDocumentFocus(hasDocument() && document.hasFocus());
  React.useEffect(updateFocus, []);
  if (documentFocus) {
    return null;
  }
  const handleClick = () => {
    updateFocus();
    props.onClick();
  };
  return <HackOverlayStyled id={props.id} onClick={handleClick} />;
};

export const ClickableOverlay: React.FunctionComponent<HackOverlayProps> = (props) => {
  return <HackOverlayStyled id={props.id} onClick={props.onClick} />;
};
