import { ForcedFade } from '@web/atomic/legacy/obj.animation/animation.component.style';
import { Blob4SectionStyled, Blob1SectionStyled } from '@lp-root/src/components/atm.background/background.styled';
import { Body, Col, FeaturedH2, Grid, H3, Row, Separator } from '@web/atomic';
import { CardWrapper, OverflowHiddenWrapper, PaddingWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import { Accordion } from '@web/atomic/legacy/mol.accordion';
import { useRadioGroup } from '@web/atomic/obj.custom-hooks/radio.hook';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import styled from 'styled-components';

import LargeSeparatorRow from '@lp-root/src/components/atm.large-separator-row/large-separator-row.component';

interface IHcpLandingPageWhatIsVigilantesDoSonoSectionProps {
  progress: number;
}
export const HcpLandingPageWhatIsVigilantesDoSonoSection: React.FunctionComponent<IHcpLandingPageWhatIsVigilantesDoSonoSectionProps> = (
  props
) => {
  const data = useStaticQuery(graphql`
    {
      mockupHome: file(relativePath: { eq: "mockup-home.png" }) {
        childImageSharp {
          gatsbyImageData(width: 700, layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      mockupReport: file(relativePath: { eq: "mockup-report.png" }) {
        childImageSharp {
          gatsbyImageData(width: 700, layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      mockupPatientList: file(relativePath: { eq: "mockup-patient-list.png" }) {
        childImageSharp {
          gatsbyImageData(width: 700, layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  `);

  const sections = [
    {
      title: 'Para seus pacientes',
      image: data.mockupHome.childImageSharp.gatsbyImageData,
      description: (
        <>
          A Vigilantes do Sono é um programa digital de melhoria da qualidade do sono. O protocolo é baseado na{' '}
          <strong>Terapia Cognitivo-Comportamental para Insônia (TCC-i)</strong>. <br />
          No programa, o(a) paciente é conduzido pela nossa assistente virtual, a Sônia, que <strong>diariamente</strong> coleta dados do
          sono da pessoa e ensina técnicas para dormir melhor.
        </>
      ),
    },
    {
      title: 'Para você',
      image: data.mockupPatientList.childImageSharp.gatsbyImageData,
      description: (
        <>
          Você tem um <strong>portal para acompanhar a evolução</strong> dos seus pacientes no programa, receber alertas com as principais
          informações de cada paciente e enviar mensagens que aparecerão direto no app para o(a) paciente.
        </>
      ),
    },
    {
      title: 'Para suas consultas',
      image: data.mockupReport.childImageSharp.gatsbyImageData,
      description: (
        <>
          Em cada relatório você pode analisar a evolução do(a) paciente e entender{' '}
          <strong>indicadores sugestivos do que trabalhar com ele(a)</strong> durante a consulta. Você tem acesso a informações de forma
          gráfica que pode mostrar e conversar com o(a) paciente bem como incluir no prontuário dele(a).
        </>
      ),
    },
  ];

  const [openId, openDict, setOpenAccordionId] = useRadioGroup(null);
  return (
    <>
      <Grid>
        <LargeSeparatorRow />
      </Grid>
      <Blob1SectionStyled>
        <OverflowHiddenWrapper>
          <Grid overflow={'hidden'}>
            <Row>
              <Col xs={12}>
                <FeaturedH2>O que é a Vigilantes do Sono</FeaturedH2>
              </Col>
            </Row>
            <Row reverse middle="xs">
              <Col xs={12} sm={12} md={6} lg={7}>
                <TranslateFromX progress={props.progress} fromRight={true}>
                  <Blob4SectionStyled>
                    <ForcedFade show={true} key={openId == 'null' ? sections[0].title : openId}>
                      <GatsbyImage
                        image={sections.find((s) => s.title === openId)?.image ?? sections[0].image}
                        alt={openId as string}
                        loading="eager"
                      />
                    </ForcedFade>
                  </Blob4SectionStyled>
                </TranslateFromX>
                <Separator />
              </Col>
              <Col xs={12} sm={12} md={6} lg={5}>
                {sections.map((item) => {
                  const accordionId = item.title;
                  return (
                    <React.Fragment key={accordionId}>
                      <CardWrapper noPadding={true}>
                        <Accordion
                          accordionId={accordionId}
                          expanded={openDict[accordionId]}
                          onHeaderTap={(id, selected) => setOpenAccordionId(selected ? id : null)}
                        >
                          <Accordion.Header>
                            <H3 cell>{item.title}</H3>
                          </Accordion.Header>
                          <PaddingWrapper>
                            <Body>{item.description}</Body>
                          </PaddingWrapper>
                        </Accordion>
                      </CardWrapper>
                      <Separator />
                    </React.Fragment>
                  );
                })}
              </Col>
            </Row>
          </Grid>
        </OverflowHiddenWrapper>
      </Blob1SectionStyled>
      <Grid>
        <LargeSeparatorRow />
        <LargeSeparatorRow />
        <LargeSeparatorRow />
        <LargeSeparatorRow />
        <LargeSeparatorRow />
        <LargeSeparatorRow />
      </Grid>
    </>
  );
};

const TranslateFromX = styled.div<{ progress: number; fromRight?: boolean }>`
  transform: translateX(${(props) => (1 - props.progress) * 200 * (props.fromRight ? 1 : -1)}px);
  opacity: ${(props) => props.progress};
  visibility: ${(props) => (props.progress === 0 ? 'hidden' : 'visible')};
  transition: transform 0.3s, opacity 0.3s;
`;
