import { whatsappLink } from '@global/utils/url/create-whatsapp-url';
import { Blob2SectionStyled } from '@lp-root/src/components/atm.background/background.styled';
import { GatsbyButton } from '@lp-root/src/components/atm.button/button.component';
import LargeSeparatorRow from '@lp-root/src/components/atm.large-separator-row/large-separator-row.component';
import { EmailContacts } from '@lp-root/src/domain/contacts';
import { appPaths } from '@lp-root/src/utils/path';
import { Body, Col, FeaturedH2, Grid, Row } from '@web/atomic';
import * as React from 'react';
import { HcpFAQuestionKey } from '../../about/faq-utils';
import FaqComponent from '../../about/faq.component';
import { HcpFAQuestions } from '../../about/hcp-faq.utils';

export const HcpLandingPageFAQSection: React.FunctionComponent = () => {
  return (
    <>
      <Grid>
        <LargeSeparatorRow />
        <LargeSeparatorRow />
        <LargeSeparatorRow />
        <LargeSeparatorRow />
      </Grid>
      <Blob2SectionStyled>
        <Grid>
          <Row>
            <Col xs={12}>
              <FeaturedH2>Perguntas frequentes </FeaturedH2>
            </Col>
          </Row>
          <Row center={'xs'} mb>
            <Col xs={12} md={8} lg={9}>
              <Body>
                Para quaisquer dúvidas ou dificuldades que você ou seu paciente vierem a ter, podem entrar em contato com nosso suporte no
                número de{' '}
                <a href={whatsappLink('sales')} rel="noreferrer" target="_blank">
                  {' '}
                  WhatsApp: +55 (11) 91157-9831{' '}
                </a>{' '}
                , no link <a href="https://vigilantesdosono.com/whatsapp-profissionais">vigilantesdosono.com/whatsapp-profissionais</a> ou
                no e-mail: <a href={`mailto:${EmailContacts.Support}`}>{EmailContacts.Support}</a>.
              </Body>
            </Col>
          </Row>
          <Row center={'xs'}>
            <Col xs={12} md={8} lg={9}>
              <FaqComponent
                allQuestions={HcpFAQuestions}
                questionsToShow={[
                  HcpFAQuestionKey.CBTi,
                  HcpFAQuestionKey.CBTiVsSleepHygiene,
                  HcpFAQuestionKey.ForWhom,
                  HcpFAQuestionKey.Contraindications,
                  HcpFAQuestionKey.Telemonitor,
                  HcpFAQuestionKey.FindTheApp,
                ]}
              />
            </Col>
          </Row>

          <Row center={'xs'} mt>
            <Col xs={12} sm={6}>
              <GatsbyButton id="ga-faq" to={appPaths.hcpFaq.path} kind="link" expanded>
                Ver mais perguntas e respostas
              </GatsbyButton>
            </Col>
          </Row>
          <LargeSeparatorRow />
        </Grid>
      </Blob2SectionStyled>
    </>
  );
};
