import { GatsbyButton } from '@lp-root/src/components/atm.button/button.component';
import TestimonialVideo from '@lp-root/src/components/org.testimonial-video/testimonial-video.component';
import { appPaths } from '@lp-root/src/utils/path';
import { Col, FeaturedH2, Grid, Row } from '@web/atomic';
import { Fade } from '@web/atomic/legacy/obj.animation/animation.component.style';
import * as React from 'react';
import { InView } from 'react-intersection-observer';

export const HcpLandingPageTestiomonialsSection: React.FunctionComponent = () => {
  return (
    <InView triggerOnce={true}>
      {({ inView, ref }) => (
        <Grid>
          <Row>
            <Col xs={12}>
              <FeaturedH2>Depoimentos</FeaturedH2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Fade ref={ref} show={inView} delay={0.3}>
                <TestimonialVideo
                  context="dalva"
                  name="Dra. Dalva Poyares"
                  description="Médica Neurologista"
                  videoUrl="https://www.youtube.com/watch?v=nWLp_s6QCIo"
                />
              </Fade>
            </Col>
            <Col xs={12} md={6}>
              <Fade show={inView} delay={1}>
                <TestimonialVideo
                  context="helena"
                  name="Dra. Helena Hachul"
                  description="Médica Ginecologista"
                  videoUrl="https://www.youtube.com/watch?v=JZtXj5gQ6i8"
                />
              </Fade>
            </Col>
          </Row>
          <Row center={'xs'} mt>
            <Col xs={12} sm={6}>
              <GatsbyButton id="ga-patient-testimonials" to={appPaths.testimonials.path} kind="link" expanded>
                Os pacientes também adoram, veja os relatos
              </GatsbyButton>
            </Col>
          </Row>
        </Grid>
      )}
    </InView>
  );
};
